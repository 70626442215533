import * as axios from 'axios';

const instance = axios.create({
  crossDomain: true,
  baseURL: 'https://api.flyjets.com/api/newsletter',
  withCredentials: true,
  contentType: 'application/json',
});

export const apiClient = {
  saveNewsletter (data) {
    return instance.post('createNewsletter', data, {});
  },
};
