import * as axios from 'axios';

const instance = axios.create({
  crossDomain: true,
  baseURL: process.env.VUE_APP_HTML_GENERATOR_URI + '/templates',
  withCredentials: true,
  contentType: 'application/json',
});

export const htmlGeneratorAPI = {
  generateNewsletter (data, senderSystem) {
    return instance.post(`newsletter/${senderSystem}`, data, {});
  },
};