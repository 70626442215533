<template>
  <div class="newsletterGeneratorDashboard">
    <vue-scroll :ops="ops">
      <div class="newsletterGeneratorDashboard__header">
        <div class="newsletterGeneratorDashboard__title">Newsletter JSON generator</div>
      </div>
      <div class="newsletterGenerator__intro">
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Intro</div>
          <textarea v-model="intro" />
        </div>
      </div>
      <div class="newsletterGenerator__header">TODAY'S TOP STORIES</div>
      <div class="newsletterGenerator__stories" v-for="(item, index) in stories" :key="item.key">
        <div class="newsletterGenerator__subheader">{{index + 1}} story</div>
        <div class="newsletterGeneratorDashboard__button" @click="loadStory(item)">
          <span class="text">Load story</span>
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Title</div>
          <textarea v-model="item.title" />
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Link</div>
          <textarea v-model="item.link" />
        </div>
      </div>
      <div class="newsletterGeneratorDashboard__button" @click="addStoriesRow">
        <span class="text">Add story</span>
      </div>
      <div class="newsletterGenerator__header">TOP ARTICLES</div>
      <div class="newsletterGenerator__articles" v-for="(item, index) in articles" :key="item.key">
        <div class="newsletterGenerator__subheader">{{index + 1}} article</div>
        <div class="newsletterGeneratorDashboard__button" @click="loadArticle(item)">
          <span class="text">Load article</span>
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Link</div>
          <textarea v-model="item.link" />
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Date</div>
          <textarea v-model="item.published" />
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Heading</div>
          <textarea v-model="item.title" />
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Subheading</div>
          <textarea v-model="item.subtitle" />
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">Photo link</div>
          <textarea v-model="item.cover" />
        </div>
      </div>
      <div class="newsletterGeneratorDashboard__button" @click="addArticlesRow">
        <span class="text">Add article</span>
      </div>
      <div class="newsletterGenerator__news" style="margin-bottom: 20px">
        <div class="newsletterGenerator__header">MORE NEWS</div>
        <div class="newsletterGenerator__news" v-for="(item) in newsEmail" :key="item.key">
          <div class="newsletterGenerator__row news">
            <textarea v-model="item.text" />
          </div>
        </div>
        <div class="newsletterGeneratorDashboard__button" @click="addNewsEmailAndWeb">
          <span class="text">Add news</span>
        </div>
      </div>
      <div class="newsletterGenerator__stories" v-for="(item, index) in banners" :key="item.key">
        <br v-if="index > 0" class="newsletterGenerator__subheader"/>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">{{getBannersUrlCaption(index)}}</div>
          <textarea v-model="item.bannerUrl" />
        </div>
        <div class="newsletterGenerator__row">
          <div class="newsletterGenerator__row__title">{{getBannersImageUrlCaption(index)}}</div>
          <textarea v-model="item.bannerImageUrl" />
        </div>
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGenerator__row__title">Edition</div>
        <textarea v-model="edition" />
      </div>
      <div class="newsletterGeneratorDashboard__button" @click="addBannersRow">
        <span class="text">Add banner</span>
      </div>
      <div class="newsletterGenerator__news">
        <div class="newsletterGenerator__header">WEB NEWS</div>
        <div class="newsletterGenerator__news" v-for="(item) in newsWeb" :key="item.key">
          <div class="newsletterGenerator__row news">
            <textarea v-model="item.text" />
          </div>
        </div>
        <div class="newsletterGeneratorDashboard__button" @click="addNewsWeb">
          <span class="text">Add news</span>
        </div>
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGenerator__row__title">Updates Text</div>
        <textarea v-model="updatesText" />
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGenerator__row__title">Updates Button Text</div>
        <textarea v-model="updatesButtonText" />
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGenerator__row__title">Updates Button's Url</div>
        <textarea v-model="updatesButtonUrl" />
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGenerator__row__title">Greeting</div>
        <textarea v-model="addGreeting" />
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGeneratorDashboard__button penultimate" @click="generateJsonMailChimp">
          <span class="text">Save html M</span>
        </div>
      </div>
      <div class="newsletterGenerator__row">
        <div class="newsletterGeneratorDashboard__button bottom" @click="generateJsonKlaviyo">
          <span class="text">Save html K</span>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import moment from 'moment'
import './NewsletterJsonGenerator.styl'
import { blogsAPI } from '@/api/blog'
import { apiClient } from '@/api/admin/apiClient'
import { htmlGeneratorAPI } from '@/api/admin/newsletterGenerator'

export default {
    name: 'newsletter-json-generator',
    data: () => ({
        intro: '',
        banners: [{
          bannerUrl: '',
          bannerImageUrl: '',
        }],
        updatesText: '',
        updatesButtonText: '',
        updatesButtonUrl: '',
        addGreeting: '',
        numberOfRecords: 1, // 0
        edition: '',
        stories: [{
          link: '',
          title: '',
        }],
        articles: [{
          link: '',
          published: '',
          title: '',
          subtitle: '',
          cover: '',
        }],
        newsEmail: [{
          text: '',
          isWebNews: false,
        }],
        newsWeb: [{
          text: '',
          isWebNews: true,
        }],
        ops: {
          rail: {
            size: '3px',
          },
          bar: {
            size: '3px',
            background: 'rgba(0,0,0,0.1)',
          },
        },
    }),
    methods: {
      moment,
      async generateJsonKlaviyo () {
        await this.generateJson('klaviyo');
      },
      async generateJsonMailChimp () {
        await this.generateJson('mailchimp');
      },
      async generateJson (senderSystem) {
        const news = this.newsEmail.map((item) => {
          const fields = item.text.split(/\r?\n/);

          for (let i = 0; i < fields.length; i++) {
            if (fields[i] === '') {
              fields.splice(i, 1);
              i--;
            }
          }

          const splittedNewsDate = fields[0].split(' ');
          const newsPublishedDate = splittedNewsDate[0] + '​ ' + splittedNewsDate[1];

          return {
            published: newsPublishedDate,
            author: fields[1],
            title: fields[2],
            link: fields[3],
          };
        })
        const updates = {
          updatesText: this.updatesText,
          updatesButtonText: this.updatesButtonText,
        }

        if (this.isValidEmail(this.updatesButtonUrl)) {
          updates.updatesButtonUrl = 'mailto:' + this.updatesButtonUrl;
        } else {
          updates.updatesButtonUrl = this.updatesButtonUrl;
        }

        const topArticles = this.articles.map((item) => {
          const splittedArticleDate = item.published.split(' ');
          const articlePublishedDate = splittedArticleDate[0] + '​ ' + splittedArticleDate[1];

          return {
            link: item.link,
            published: articlePublishedDate,
            title: item.title,
            subtitle: item.subtitle,
            cover: item.cover,
          };
        });

        const jsonData = {
          greeting: this.addGreeting === 'Y',
          intro: this.intro,
          updates: updates,
          edition: this.edition,
          topStories: this.stories,
          topArticles: topArticles,
          allArticles: news,
        };

        const editedBanners = [];

        for (let i = 0; i < this.banners.length; i++) {
          let bannerUrl = this.banners[i].bannerUrl;
          if (bannerUrl === '' || this.banners[i].bannerImageUrl === '') {
            continue;
          }
          if (this.isValidEmail(this.banners[i].bannerUrl)) {
            bannerUrl = 'mailto:' + bannerUrl;
          }
          editedBanners.push({
            bannerUrl: bannerUrl,
            bannerImageUrl: this.banners[i].bannerImageUrl,
          })
        }

        jsonData.banners = editedBanners;

        console.log(jsonData);

        const success = await this.saveNewsletter();
        if (!success) {
          return;
        }

        try {
          const response = await htmlGeneratorAPI.generateNewsletter(jsonData, senderSystem);

          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', response.data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'newsletter_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.html';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          const message = {
            title: 'Failed to save HTML',
          };
          this.$modal.show('ThanksPopup', {message})

          console.log(error)
        }
      },

      async saveNewsletter () {
        try {
          let orderNumber = 0;

          const news = this.newsEmail
          .concat(this.newsWeb)
          .filter(item => item.text.length > 0)
          .map((item) => {
            const fields = item.text.split(/\r?\n/);
            for (let i = 0; i < fields.length; i++) {
              if (fields[i] === '') {
                fields.splice(i, 1);
                i--;
              }
            }

            const separatedTitle = fields[2]?.split('//');
            const title = separatedTitle ? separatedTitle[0] : '';

            const categories = separatedTitle
              ? separatedTitle[1]
                ? separatedTitle[1].split(',')
                : null
              : null;

            const trimedCategories = categories.map(c => c.trim());

            return {
              published: fields[0],
              author: fields[1],
              title: title,
              categories: trimedCategories,
              link: fields[3],
              orderNumber: orderNumber++,
              numberOfRecords: +this.numberOfRecords,
              webNews: item.isWebNews,
            };
          })

          const jsonData = {
            allArticles: news,
          };

          if (!this.validateNewsletter(jsonData)) {
            return false;
          }

          const saveNewsletterResponse = await apiClient.saveNewsletter(jsonData);

          if (!saveNewsletterResponse.data.isSuccessfull) {
            const message = {
              title: 'Failed to save HTML',
              text: saveNewsletterResponse.data.message,
            };
            this.$modal.show('ThanksPopup', {message});
            return false;
          }
          return true;
        } catch (error) {
          const message = {
            title: 'Failed to save HTML',
          };
          this.$modal.show('ThanksPopup', {message})

          console.log(error)

          return false;
        }
      },
      validateNewsletter (jsonData) {
        let errorMessage = null;
        let errorDetails = null;

        if (jsonData.allArticles.length === 0) {
          errorMessage = 'At least 1 news for email and web is required';
        } else {
          jsonData.allArticles.forEach(news => {
            if (!news.title) {
              errorMessage = 'News for email and web or web only title is required';
            }
            if (!news.author) {
              errorMessage = 'News for email and web or web only author is required';
            }
            if (!news.published) {
              errorMessage = 'News for email and web or web only date is required';
            }
            if (!this.isValidUrl(news.link)) {
              errorMessage = 'Invalid news for email and web or web only url';
              errorDetails = news.link;
            }
          })
        }

        if (errorMessage) {
          const message = {
            title: errorMessage,
            text: errorDetails,
          };
          this.$modal.show('ThanksPopup', {message})
          return false;
        }
        return true;
      },
      addStoriesRow () {
        this.stories = [...this.stories, { link: '', title: ''}];
      },
      addBannersRow () {
        this.banners = [...this.banners, { bannerUrl: '', bannerImageUrl: ''}];
      },
      addArticlesRow () {
        this.articles = [...this.articles, {
          link: '',
          published: '',
          title: '',
          subtitle: '',
          cover: '',
        }];
      },
      addNewsEmailAndWeb () {
        this.newsEmail = [...this.newsEmail, { text: '', isWebNews: false}];
      },
      addNewsWeb () {
        this.newsWeb = [...this.newsWeb, { text: '', isWebNews: true}];
      },
      async loadStory (item) {
        console.log(item);
        if (item.link) {
          const story = await this.getArticle(item.link);
          if (story) {
            item.title = story.title;
          }
        }
      },
      async loadArticle (item) {
        if (item.link) {
          const article = await this.getArticle(item.link);
          if (article) {
            item.title = article.title;
            item.subtitle = article.subheading;
            item.cover = article.cover.data.attributes.url;
          }
        }
      },
      async getArticle (fullPath) {
        const fullPathWithoutSlash = fullPath.endsWith('/') ? fullPath.slice(0, -1) : fullPath;
        const articlePath = fullPathWithoutSlash.split('/').at(-1);
        const { data } = await blogsAPI.getArticle(articlePath);
        if (data.data.length) {
          return data.data[0].attributes;
        } else {
          const message = {
            title: 'Article not found',
          };
          this.$modal.show('ThanksPopup', {message})
          return false;
        }
      },
      validate (jsonData) {
        let errorMessage = null;
        let errorDetails = null;

        if (!jsonData.intro) {
          errorMessage = 'Intro is required';
        }

        if (jsonData.topStories.length === 0) {
          errorMessage = 'At least 1 story is required';
        } else {
          jsonData.topStories.forEach(story => {
            if (!story.title) {
              errorMessage = 'story title is required';
            }
            if (!this.isValidUrl(story.link)) {
              errorMessage = 'Invalid story url';
              errorDetails = story.link;
            }
          })
        }

        if (jsonData.topArticles.length === 0) {
          errorMessage = 'At least 1 article is required';
        } else {
          jsonData.topArticles.forEach(article => {
            if (!article.title) {
              errorMessage = 'article heading is required';
            }
            if (!article.published) {
              errorMessage = 'article date is required';
            }
            if (!article.subtitle) {
              errorMessage = 'article subheading is required';
            }
            if (!this.isValidUrl(article.link)) {
              errorMessage = 'Invalid article url';
              errorDetails = article.link;
            }
            if (!this.isValidUrl(article.cover)) {
              errorMessage = 'Invalid article image url';
              errorDetails = article.cover;
            }
          })
        }

        if (jsonData.allArticles.length === 0) {
          errorMessage = 'At least 1 news for email and web is required';
        } else {
          jsonData.allArticles.forEach(news => {
            if (!news.title) {
              errorMessage = 'news for email and web title is required';
            }
            if (!news.author) {
              errorMessage = 'news for email and web author is required';
            }
            if (!news.published) {
              errorMessage = 'news for email and web date is required';
            }
            if (!this.isValidUrl(news.link)) {
              errorMessage = 'Invalid news for email and web url';
              errorDetails = news.link;
            }
          })
        }

        if (!this.isValidUrl(jsonData.bannerImageUrl)) {
          errorMessage = 'Invalid banner url';
        }

        if (errorMessage) {
          const message = {
            title: errorMessage,
            text: errorDetails,
          };
          this.$modal.show('ThanksPopup', {message})
          return false;
        }
        return true;
      },
      isValidUrl (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
      },
      isValidEmail (email) {
        return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      },
      getBannersUrlCaption (index) {
        if (index > 0) {
          return `Banner ${index + 1} url`;
        } else {
          return "Banner's url";
        }
      },
      getBannersImageUrlCaption (index) {
        if (index > 0) {
          return `Banner ${index + 1} image url`;
        } else {
          return "Banner's image url";
        }
      },
    },
}
</script>
